

















































































































import VueBaseWidget from '@/utils/widgets/VueBaseWidget';
import { Component, Prop } from 'vue-property-decorator';
import { Getter, namespace } from 'vuex-class';
import FileResourceHelper from '@utils/helpers/FileResourceHelper';
import CommunityFeature from '@/models/graphql/CommunityFeature';
import { FeatureKeys } from '@/utils/enums/FeatureKeys';
import SignInChangeEmail from '@/components/account/SignInChangeEmail.vue';
import SignInEnterEmail from '@/components/account/SignInEnterEmail.vue';
import SignInEnterInformation from '@/components/account/SignInEnterInformation.vue';
import SignInEnterPassword from '@/components/account/SignInEnterPassword.vue';
import SignInStep from '@/utils/enums/SignInStep';
import SignInEmailSent from '@/components/account/SignInEmailSent.vue';
import SignInCreatePassword from '@/components/account/SignInCreatePassword.vue';
import SignInEmailDisambiguated from '@/components/account/SignInEmailDisambiguated.vue';
import SignInNoSignUp from '@/components/account/SignInNoSignUp.vue';
import SignInSignUp from '@/components/account/SignInSignUp.vue';
import EmailType from '@/utils/enums/EmailType';
import SignInPasswordReset from '@/components/account/SignInPasswordReset.vue';
import InvalidSignIn from '@/components/account/InvalidSignIn.vue';
import LoginProviderConfig from '@/models/graphql/LoginProviderConfig';

const authenticationStore = namespace('AuthenticationStore');
const loginProviderConfigStore = namespace('LoginProviderConfigStore');

@Component({
  components: {
    SignInEnterEmail,
    SignInEnterInformation,
    SignInEnterPassword,
    SignInCreatePassword,
    SignInEmailDisambiguated,
    SignInEmailSent,
    SignInChangeEmail,
    SignInNoSignUp,
    SignInSignUp,
    SignInPasswordReset,
    InvalidSignIn,
  },
  beforeRouteEnter(to, from, next) {
    const isLoginPathHasRedirection = from.name !== null
        && from.name !== 'enter-email'
        && from.name !== 'sign-up'
        && from.name !== 'no-sign-up'
        && from.name !== 'email-sent'
        && from.name !== 'enter-information'
        && from.name !== 'email-disambiguated'
        && from.name !== 'change-email'
        && from.name !== 'enter-password'
        && from.name !== 'password-reset'
        && from.name !== 'create-password';
    if (isLoginPathHasRedirection) {
      next((vm) => {
        if (vm.$store.state.sessionSelectedTab) {
          Object.assign(from.query, { sessionSelectedTab: vm.$store.state.sessionSelectedTab });
          vm.$store.commit('setSessionSelectedTab', null);
        }
        let redirect = encodeURIComponent(vm.$router.resolve({
          params: from.params,
          path: from.path,
          query: from.query,
        }).href);
        if (from.query && from.query.redirect) {
          redirect = from.query.redirect as string;
        } else if (from.name === 'sign-in') {
          redirect = '/';
        }
        if (redirect) {
          const toRoute = vm.$router.resolve({
            name: to.name as string,
            params: to.params,
            query: { redirect },
          });
          vm.$router.replace(toRoute.location);
        }
      });
    }
    next((vm) => {
      const me = vm as SignInPage;
      if (me.isAuthenticated) {
        me.goHome();
      }
    });
    next();
  },
})
export default class SignInPage extends VueBaseWidget {
  @Prop({
    required: false,
    default: '',
  })
  message!: string;

  @Prop({
    required: false,
    default: '',
  })
  token!: string;

  @Prop({
    required: false,
    default: '',
  })
  errorMessage!: string;

  @Prop({
    required: false,
    default: '',
  })
  pageType!: SignInStep;

  @Prop({
    required: false,
    default: EmailType.WELCOME_EMAIL,
  })
  emailType!: EmailType;

  @Prop({
    required: false,
    default: '',
  })
  afterLogin!: string;

  @Getter
  protected featureByKey!: (key: FeatureKeys) => CommunityFeature;

  @Getter
  protected readonly authStep!: SignInStep | null;

  @Getter
  private isLoading!: boolean;

  @authenticationStore.Getter
  private isAuthenticated!: boolean;

  private emailToken: string | null = null;

  private signInStep = SignInStep;

  private invalidSignIn = false;

  @authenticationStore.Action
  private resetAuthErrors!: () => void;

  @authenticationStore.Action
  private setAuthStep!: (step: SignInStep) => void;

  @loginProviderConfigStore.Action
  private loadLoginProviderConfig!: (filter: {
    schemaCode: string;
    loginProviderId: number;
  }) => Promise<LoginProviderConfig>;

  private get logoPath(): string {
    if (this.community?.logoFileResource?.path) {
      return FileResourceHelper
        .getImagePathWithSize(FileResourceHelper.getFullPath(this.community?.logoFileResource), 'w96');
    }
    return '';
  }

  private get contactSupportHref(): string {
    return `mailto:${this.community?.organizer?.email}`;
  }

  private get isSignupEnabled(): boolean {
    return this.featureByKey(FeatureKeys.USER_SIGNUP)
        && this.featureByKey(FeatureKeys.USER_SIGNUP).enabled;
  }

  created(): void {
    if (this.$route.query.success === 'false' && this.$route.query.login_provider_id) {
      this.setAuthStep(this.signInStep.INVALID_SIGN_IN);
      if (this.community.code) {
        const loginProviderId = parseInt(this.$route.query.login_provider_id as string, 10);
        this.loadLoginProviderConfig({
          schemaCode: this.community.code,
          loginProviderId,
        })
          .then((response: LoginProviderConfig) => {
            window.location
              .href = `${response.oauthLogOutUrl}?client_id=${response.oauthClientId}`;
          });
      }
    } else if (this.$route.query.success === 'false') {
      this.setAuthStep(this.signInStep.INVALID_SIGN_IN);
      this.invalidSignIn = this.$route.query.success === 'false';
    } else {
      if (this.pageType === SignInStep.CHANGE_EMAIL && this.$route.query && this.$route.query.emailToken) {
        this.emailToken = this.$route.query.emailToken as string;
      }

      if (this.authStep === null && !this.pageType) {
        this.setAuthStep(SignInStep.ENTER_EMAIL);
      }
      if (this.pageType) {
        this.setAuthStep(this.pageType);
      }
    }
  }

  mounted(): void {
    this.resetAuthErrors();
  }

  private goHome(): void {
    this.$router.push({ path: '/' });
  }

  private onSignUpClick(): void {
    this.$router.push({ name: 'sign-up' });
  }

  private onSignInClick(): void {
    this.$router.push({ name: 'sign-in' });
  }
}
